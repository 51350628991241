import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { GlobalService } from '../global.service';

@Injectable({ providedIn: 'root' })
export class MasterServicesService {
  IoTAPI: string = environment.API_URL;
  _HeadersLogin: any;
  constructor(private _http: HttpClient, private _GS: GlobalService) { }
  GetStatesDropdown(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/MasterDataMgmtAPI/GetAllStateMastersByCountryId', request, this._HeadersLogin);
  }
  ServiceTypeDropdown(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/MasterDataMgmtAPI/GetAllServiceTypeMasters', request, this._HeadersLogin);
  }
  GetSigningClosingTypesDropdown() {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.get(this.IoTAPI + 'api/MasterDataMgmtAPI/GetSigningClosingTypes', this._HeadersLogin);
  }
  RelationshipDropdown(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/MasterDataMgmtAPI/GetAllRelationshipMasters', request, this._HeadersLogin);
  }
  TimezoneDropdown(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/MasterDataMgmtAPI/GetAllTimeZoneMasters', request, this._HeadersLogin);
  }
  ClientOriginatorsDropdown(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/MasterDataMgmtAPI/GetAllClientOriginators', request, this._HeadersLogin);
  }
  ClientLendersDropdown(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/MasterDataMgmtAPI/GetAllClientLenders', request, this._HeadersLogin);
  }
  ChangePassword(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/UserMgmtAPI/ChangePassword', request, this._HeadersLogin);
  }
  RegisterServiceAdminUser(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/UserMgmtAPI/RegisterServiceAdminUser', request, this._HeadersLogin);
  }
  GetloginUserDetailsById(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/UserMgmtAPI/GetloginUserDetailsById', request, this._HeadersLogin);
  }
  GetAllServiceAdminUser(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/UserMgmtAPI/GetAllServiceAdminUser', request, this._HeadersLogin);
  }
  GetServiceAdminUserById(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/UserMgmtAPI/GetServiceAdminUserById', request, this._HeadersLogin);
  }
  UpdateServiceAdminCurrentStatus(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/UserMgmtAPI/UpdateServiceAdminCurrentStatus', request, this._HeadersLogin);
  }
  UpdateServiceAdminUser(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/UserMgmtAPI/UpdateServiceAdminUser', request, this._HeadersLogin);
  }
  GetAllFeeScheduleForClient(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/ClientMgmtAPI/GetAllFeeScheduleForClient', request, this._HeadersLogin);
  }
  GetFeeSchedule(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/ClientMgmtAPI/GetFeeSchedule', request, this._HeadersLogin);
  }
  AddOrUpdateFeeSchedule(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/ClientMgmtAPI/AddOrUpdateFeeSchedule', request, this._HeadersLogin);
  }
  RegisterGeneralUser(request) {
    return this._http.post(this.IoTAPI + 'api/UserMgmtAPI/RegisterGeneralUser', request, this._HeadersLogin);
  }
  GetEmailNotificationsLogForLoginUser(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/UserMgmtAPI/GetEmailNotificationsLogForLoginUser', request, this._HeadersLogin);
  }
  GetEmailNotificationsLogForAccountUser(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/UserMgmtAPI/GetEmailNotificationsLogForAccount', request, this._HeadersLogin);
  }
  GetEmailNotificationsLogForClient(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/ClientMgmtAPI/GetEmailNotificationsLogForClient', request, this._HeadersLogin);
  }
  GetEmailNotificationsLogForNotary(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/NotaryMgmtAPI/GetEmailNotificationsLogForNotary', request, this._HeadersLogin);
  }
  GetEmailNotificationsLogForSigning(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/SigningMgmtAPI/GetEmailNotificationsLogForSigning', request, this._HeadersLogin);
  }
  GetActivityLog(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/CommonAPI/GetActivityLog', request, this._HeadersLogin);
  }
  UpdateActivityLog(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/CommonAPI/UpdateActivityLog', request, this._HeadersLogin);
  }

  ExportSigningList(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/CommonAPI/ExportSigningList', request, this._HeadersLogin);
  }
  ExportSigningFile(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/CommonAPI/ExportSigningFile', request, this._HeadersLogin);
  }
  ExportClientList(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/CommonAPI/ExportClientList', request, this._HeadersLogin);
  }
  ExportClientFile(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/CommonAPI/ExportClientFile', request, this._HeadersLogin);
  }
  ExportNotaryList(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/CommonAPI/ExportNotaryList', request, this._HeadersLogin);
  }
  ExportNotaryFile(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/CommonAPI/ExportNotaryFile', request, this._HeadersLogin);
  }
  MarkAllFeesPaid(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/CommonAPI/MarkAllFeesPaid', request, this._HeadersLogin);
  }
  SendLoginData(request) {
    this._HeadersLogin = this._GS.GetJsonHeader();
    return this._http.post(this.IoTAPI + 'api/CommonAPI/SendLoginData', request, this._HeadersLogin);
  }
  


}