import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
@Injectable()
export class GlobalService {
  _loggedBy: any;
  EsCpIsLoggedBy = '_eS_cp_isLoggedby';
  EsCpIsLoggedIn = '_eS_cp_isLoggedin';
  constructor(private router: Router) { }
  public GetJsonHeader_NoLogin() {
    const HeaderData = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return HeaderData;
  }
  public GetJsonHeader() {
    let HeaderData = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const _loggedBy = this.GetLoggedUser();
    if (_loggedBy.UserId == null) {
      HeaderData = { headers: new HttpHeaders({ Authorization: 'Bearer', 'Content-Type': 'application/json', authUser: '0' }) };
    }
    else if (_loggedBy.access_token === '' || _loggedBy.access_token == null) {
      HeaderData = { headers: new HttpHeaders({ Authorization: 'Bearer', 'Content-Type': 'application/json', authUser: '0' }) };
    }
    else HeaderData = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + _loggedBy.access_token, 'Content-Type': 'application/json', authUser: _loggedBy.UserId + ''
      })
    };
    return HeaderData;
  }
  public GetFileUploadHeader() {
    let HeaderData = { headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }) };
    const _loggedBy = this.GetLoggedUser();
    if (_loggedBy.UserId == null) { HeaderData = { headers: new HttpHeaders({ Authorization: 'Bearer', authUser: '' }) }; }
    else if (_loggedBy.access_token === '' || _loggedBy.access_token == null) {
      HeaderData = { headers: new HttpHeaders({ Authorization: 'Bearer', authUser: '' }) };
    }
    else HeaderData = { headers: new HttpHeaders({ Authorization: 'Bearer ' + _loggedBy.access_token, authUser: _loggedBy.UserId + '' }) };
    return HeaderData;
  }

  ClearStorage() { localStorage.clear(); return true; }
  RemoveLocalStorage(a) { localStorage.removeItem(a); }
  // Logged IN Mgnt Starts
  SetLoggedUser(a) {
    localStorage.setItem(this.EsCpIsLoggedBy, a);
    localStorage.setItem(this.EsCpIsLoggedIn, 'true');
    return JSON.parse(localStorage.getItem(this.EsCpIsLoggedBy));
  }
  GetLoggedUser() {
    const UData = JSON.parse(localStorage.getItem(this.EsCpIsLoggedBy)) ? JSON.parse(localStorage.getItem(this.EsCpIsLoggedBy)) : '';
    if (UData != null) { this._loggedBy = UData; }
    return this._loggedBy;
  }
  RemoveLoggedUser() { localStorage.removeItem(this.EsCpIsLoggedBy); localStorage.removeItem(this.EsCpIsLoggedIn); }
  // Logged IN Mgnt Ends
  GoToPage(a, isNewtab: boolean = false) {
    if (isNewtab) {
      this.router.navigate([]).then(result => { window.open(a, '_blank'); });
    } else {
      this.router.navigate([a]);
    }
  }
  GetLoggedUserType() {
    const loggedUser = this.GetLoggedUser();
    if (loggedUser !== null) {
      if (loggedUser.UserType) {
        return ((loggedUser.UserType).substring(0, 1)).toUpperCase();
      }
    }
    return 0;
  }
}